import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Header from '../components/header';

class Section extends React.Component {
  render() {
    const data = this.props.data;

    let array = data.airtable.data.Pages;

    array = array.sort(function (a, b) {
      return a.data.Title.localeCompare(b.data.Title);
    });

    return (
      <Layout>
        <Header
          crumbs={[
            {
              title: data.site.siteMetadata.title,
              location: '/',
              active: false,
            },
            {
              title: data.airtable.data.Title,
              location: null,
              active: true,
            },
          ]}
        />

        {array.map((page, i) => (
          <Link
            to={page.data.Path}
            key={i}
            className="flex items-center text-black font-bold m-1"
          >
            <svg
              className="fill-current w-3 h-3 mx-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
            </svg>
            <h4>{page.data.Title}</h4>
          </Link>
        ))}
      </Layout>
    );
  }
}

export default Section;

export const query = graphql`
  query GetSection($Path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    airtable(table: { eq: "Sections" }, data: { Path: { eq: $Path } }) {
      data {
        Title
        Body {
          childMarkdownRemark {
            html
          }
        }
        Pages {
          data {
            Title
            Path
          }
        }
      }
    }
  }
`;
