import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const navHelper = function (navItem, itemNo) {
  let link;
  let separator;

  if (navItem.active) {
    link = (
      <span className="text-gray-500" aria-current="page">
        {navItem.title}
      </span>
    );
  } else {
    link = (
      <Link to={navItem.location} key={itemNo}>
        {navItem.title}
      </Link>
    );
    separator = (
      <svg
        className="fill-current w-3 h-3 mx-3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
      </svg>
    );
  }

  return (
    <li className="flex items-center" key={itemNo}>
      {link}
      {separator}
    </li>
  );
};

const Header = ({ crumbs }) => {
  const titleHome = crumbs[0].title;
  let title = titleHome;

  if (crumbs.length > 1) {
    const titlePage = crumbs.slice(-1)[0].title;
    title = `${titlePage} | ${titleHome}`;
  }

  const items = [];

  crumbs.forEach((element, index) => {
    items.push(navHelper(element, index));
  });

  return (
    <nav className="text-black font-bold my-8" aria-label="Breadcrumb">
      <Helmet title={title} defer={false} />
      <ol className="list-none p-0 inline-flex flex-col md:flex-row">
        {items}
      </ol>
    </nav>
  );
};
export default Header;
